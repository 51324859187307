import React from 'react';

import './App.css';
import { ZoomMtg } from '@zoomus/websdk';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {

  const params = new URLSearchParams(window.location.search);
  const meeting_id = params.get("meeting_id");
  const meeting_role = params.get("role");
  const meeting_name = params.get("name");
  if (meeting_id && meeting_id !== "" && meeting_name && meeting_name !== "") {
    console.log("found", meeting_id);
  } else {
    return (
      <div className="App">
        <h1 style={{color:"#fff"}}>meeting_id , meeting_name , role = 1(Host) or 0(User) parameter required</h1>
      </div>
    );
  }

  var authEndpoint = 'http://localhost/zoom/zoom-api/create_signature.php'
  var sdkKey = ''
  var meetingNumber = parseInt(meeting_id);
  var passWord = '123456'
  var role = parseInt(meeting_role);
  var userName = meeting_name
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''
  var leaveUrl = '/'

  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint+"?meeting_id="+meetingNumber+"&role="+role, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json())
    .then(response => {
      console.log("response.signature : ", response.signature);
      sdkKey = response.client_id;
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,      
      success: (success) => {
        console.log(success)
        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting SDK Sample React</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>
  );
}

export default App;
